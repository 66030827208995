.admin-container {
    padding: 20px;
}

.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
}

    .admin-header h1 {
        margin: 0;
    }

.record-item {
    position: relative; /* Container relativ positionieren */
    padding: 20px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.options-menu {
    position: absolute; /* Absolute Positionierung f�r den Men�-Container */
    top: 10px; /* Abstand vom oberen Rand des Containers */
    right: 10px; /* Abstand vom rechten Rand des Containers */
    display: flex;
    align-items: center;
}

.export-icon {
    margin-right: 10px;
    cursor: pointer;
}


.notification-icon {
    cursor: pointer;
    position: relative;
}

    .notification-icon .badge {
        position: absolute;
        top: -5px;
        right: -10px;
        background-color: red;
        color: white;
        border-radius: 50%;
        padding: 2px 6px;
        font-size: 12px;
    }

.records-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.record-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.record-details p {
    margin: 5px 0;
}

.view-participants-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
}

    .view-participants-button:hover {
        background-color: #0056b3;
    }

.participants-list {
    margin-top: 10px;
    padding-left: 20px;
}

    .participants-list li {
        list-style-type: disc;
    }

.loading {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
}

.record-manager-button {
    background-color: #0070C9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 20px; /* Abstand zum Benachrichtigungs-Icon */
    font-size: 16px;
}

    .record-manager-button:hover {
        background-color: #005bb5;
    }

.city-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
}

    .city-selector label {
        font-size: 1.2rem;
        margin-right: 10px;
        font-weight: bold;
        color: #333;
    }

    .city-selector select {
        font-size: 1rem;
        padding: 8px 12px;
        border-radius: 5px;
        border: 2px solid #4A90E2;
        background-color: #fff;
        color: #333;
        appearance: none;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease;
        font-family: Arial, sans-serif;
    }

        .city-selector select:hover {
            border-color: #2A6AB9;
        }

        .city-selector select:focus {
            border-color: #1E4D7D;
        }

        .city-selector select:active {
            background-color: #EAF2FB;
        }

        .city-selector select:before {
            content: "\25BC"; /* Custom arrow down icon */
            font-size: 0.8rem;
            color: #4A90E2;
            position: absolute;
            right: 10px;
            pointer-events: none;
        }

/* Dropdown menu styles */
.options-menu {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
    top: 30px;
    border-radius: 4px;
}

    .dropdown-menu button {
        background: none;
        border: none;
        padding: 8px 16px;
        text-align: left;
        display: block;
        width: 100%;
        cursor: pointer;
        color: black; /* Schriftfarbe auf schwarz setzen */
    }

        .dropdown-menu button:hover {
            background-color: #f1f1f1;
        }

.options-menu .dropdown-menu {
    display: block;
}


.export-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.columns-list {
    margin-bottom: 20px;
    max-height: 200px;
    overflow-y: auto;
}

    .columns-list label {
        display: block;
        margin-bottom: 5px;
    }

button {
    margin-right: 10px;
}
