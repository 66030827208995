body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
}

.login-header {
    width: 100%;
    background: #e0e0e0;
    padding: 20px 0;
    text-align: center;
}

.logo {
    width: 200px;
    height: auto;
}

.login-content {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: center;
    padding-top: 80px; /* Add padding to ensure space for the header */
}

.submit-button-login {
    background-color: #007bff; /* Bootstrap-Blau */
    color: #fff; /* Textfarbe auf wei� setzen */
    border: none; /* Rand entfernen, falls erforderlich */
    padding: 10px 20px; /* Passen Sie das Padding nach Bedarf an */
    /* Weitere Stilattribute nach Bedarf hinzuf�gen */
} 

.form-group {
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input[type="email"],
input[type="password"],
input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #0070C9;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

    button:hover {
        background-color: #005bb5;
    }

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 15px;
}

.login-footer {
    margin-top: 20px;
    font-size: 12px;
}

    .login-footer a {
        color: #0070C9;
        text-decoration: none;
    }

        .login-footer a:hover {
            text-decoration: underline;
        }

/* Anpassung f�r das Passwortfeld */
.password-container {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d; /* Optional: Farbe f�r das Icon */
}


.spinner-border {
    margin-right: 5px;
}

button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
