.user-list-container {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.header-title {
    margin: 0;
}

.header-container .fa-plus {
    position: absolute;
    right: 0;
}

.search-sort-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.search-input {
    width: 100%;
    max-width: 300px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.sort-button {
    padding: 8px 12px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    background-color: #4A90E2;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    white-space: nowrap;
}

    .sort-button:hover {
        background-color: #357ABD;
    }

.user-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
}

    .user-table th, .user-table td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        word-wrap: break-word; /* Erm�glicht Zeilenumbr�che innerhalb der Zellen */
    }

    .user-table th {
        background-color: #f2f2f2;
    }

    .user-table tr:hover {
        background-color: #f1f1f1;
    }

.dienste-container {
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-top: 10px;
}

.dienst-item {
    padding: 8px;
    margin-bottom: 5px;
    border-left: 4px solid #007bff; /* Farbe der Umrandung */
    background-color: #eef5ff; /* Hintergrundfarbe */
    border-radius: 3px;
}


/* Responsive Anpassungen f�r kleinere Bildschirme */
@media (max-width: 768px) {
    .search-sort-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .sort-button {
        margin-left: 0;
        margin-top: 10px;
    }

    .user-table th, .user-table td {
        padding: 8px;
    }
}
