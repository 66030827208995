/* Allgemeines Layout */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f7f6;
    margin: 0;
    padding: 0;
    color: #333;
}

.admin-service-requests {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    color: #4a90e2;
}

/* Filterbereich */
.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

    .filters select, .filters input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 1rem;
        width: 48%;
    }

    .filters select {
        cursor: pointer;
    }

/* Gruppierte Requests */
.requests-grouped {
    margin-top: 20px;
}

.record-group {
    margin-bottom: 40px;
}

    .record-group h3 {
        font-size: 1.5rem;
        margin-bottom: 15px;
        color: #4a90e2;
        border-bottom: 2px solid #ddd;
        padding-bottom: 5px;
    }

/* Requests-Liste */
.requests-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

    .requests-list li {
        background-color: #f9f9f9;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 15px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        transition: transform 0.2s, box-shadow 0.2s;
    }

        .requests-list li:hover {
            transform: translateY(-3px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        .requests-list li p {
            margin: 0 0 10px;
            font-size: 1rem;
        }

.custom-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

    .custom-button:hover {
        opacity: 0.9;
    }

    .custom-button.first {
        background-color: #4caf50; /* Green color */
        color: white;
        margin-right: 10px; /* Space between the "Approve" and "Reject" buttons */
    }

    .custom-button.last {
        background-color: #f44336; /* Red color */
        color: white;
    }


/* Laden und Fehleranzeige */
.loading {
    text-align: center;
    font-size: 1.5rem;
    color: #777;
}



/* Responsives Design */
@media (max-width: 768px) {
    .filters {
        flex-direction: column;
    }

        .filters select, .filters input {
            width: 100%;
            margin-bottom: 15px;
        }

    .requests-list li {
        padding: 15px;
    }

    button {
        width: 100%;
        margin-bottom: 10px;
    }

        button:first-of-type {
            margin-right: 0;
        }
}
