.add-user-container {
    width: 400px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

    .add-user-container h2 {
        text-align: center;
        margin-bottom: 20px;
        color: #333;
    }

.add-user-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

    .form-group label {
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
        color: #555;
    }

    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
    }

    .form-group input[type="checkbox"] {
        margin-right: 10px;
    }

.submit-button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

    .submit-button:disabled {
        background-color: #ccc;
    }

.error-message {
    color: red;
    text-align: center;
    margin-bottom: 15px;
}
