/* Dienste.css */

/* Kalender */
.custom-calendar {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

    /* Kalender-Header (Monat und Jahr) */
    .custom-calendar .react-calendar__navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f0f0f0;
        color: black;
        font-weight: bold;
        padding: 10px;
    }

        .custom-calendar .react-calendar__navigation button {
            color: black;
        }

    /* Kalender-Kacheln */
    .custom-calendar .react-calendar__tile {
        padding: 10px;
        height: 100px;
        border: 1px solid #ddd;
        transition: background-color 0.3s ease;
        color: black;
    }

    .custom-calendar .react-calendar__tile--now {
        background-color: dimgrey;
        color: black;
    }

    .custom-calendar .react-calendar__tile--active {
        color: black;
    }

    .custom-calendar .react-calendar__tile:hover {
        background-color: #e6f7ff;
    }

    .custom-calendar .tile-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
    }

    /* Beschreibung */
    .custom-calendar .description {
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80px;
        position: relative;
    }

        .custom-calendar .description::after {
            content: attr(data-full-text);
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            white-space: normal;
            background-color: #fff;
            padding: 5px;
            border: 1px solid #ddd;
            z-index: 10;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            display: none;
            width: max-content;
        }

        .custom-calendar .description:hover::after {
            display: block;
        }

    /* Stil f�r den Button */
    .custom-calendar .prebook-button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 0.9rem;
    }

        .custom-calendar .prebook-button:hover {
            background-color: #0056b3;
        }

    .custom-calendar .error {
        color: red;
        font-weight: bold;
        text-align: center;
        font-size: 0.9rem;
    }

/* Jahreszeitenfarben nach Monat */
/* Winter - Blaut�ne */
.winter-january {
    background-color: #003366; /* Dunkleres Blau */
}

.winter-february {
    background-color: #336699; /* Mittelblau */
}

.winter-december {
    background-color: #99CCFF; /* Hellblau */
}

/* Fr�hling - Gr�nt�ne */
.spring-march {
    background-color: #004d00; /* Dunkelgr�n */
}

.spring-april {
    background-color: #009933; /* Mittelgr�n */
}

.spring-may {
    background-color: #66ff66; /* Hellgr�n */
}

/* Sommer - Gelbt�ne */
.summer-june {
    background-color: #ffcc00; /* Dunkelgelb */
}

.summer-july {
    background-color: #ffff66; /* Mittelgelb */
}

.summer-august {
    background-color: #ffff99; /* Hellgelb */
}

/* Herbst - Rott�ne/Oranget�ne */
.autumn-september {
    background-color: #cc3300; /* Dunkelrot/Orange */
}

.autumn-october {
    background-color: #ff6633; /* Mittelrot/Orange */
}

.autumn-november {
    background-color: #ff9966; /* Hellrot/Orange */
}


.dienste {
    padding: 20px;
}

.services-for-date {
    margin-top: 20px;
}

.services-list {
    list-style: none;
    padding: 0;
}

    .services-list li {
        background: #f8f9fa;
        margin: 10px 0;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

        .services-list li p {
            margin: 5px 0;
        }

.prebook-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

    .prebook-button:hover {
        background-color: #0056b3;
    }

.error {
    color: #dc3545;
    font-weight: bold;
}

.react-calendar__tile--active .tile-content {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
}

.tile-content .service-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-icon {
    color: #007bff;
    font-size: 1.2em;
}

/* Stadtwahl */
.city-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
}

    .city-selector label {
        font-size: 1.2rem;
        margin-right: 10px;
        font-weight: bold;
        color: #333;
    }

    .city-selector select {
        font-size: 1rem;
        padding: 8px 12px;
        border-radius: 5px;
        border: 2px solid #4A90E2;
        background-color: #fff;
        color: #333;
        appearance: none;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease;
        font-family: Arial, sans-serif;
    }

        .city-selector select:hover {
            border-color: #2A6AB9;
        }

        .city-selector select:focus {
            border-color: #1E4D7D;
        }

        .city-selector select:active {
            background-color: #EAF2FB;
        }

        .city-selector select:before {
            content: "\25BC"; /* Custom arrow down icon */
            font-size: 0.8rem;
            color: #4A90E2;
            position: absolute;
            right: 10px;
            pointer-events: none;
        }

/* Stil f�r die kommende Dienste-Komponente */
.upcoming-services {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

    .upcoming-services h3 {
        font-size: 1.5em;
        margin-bottom: 20px;
        text-align: center;
        color: #333;
    }

.upcoming-services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none;
}

.upcoming-service-box {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 220px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

    .upcoming-service-box:hover {
        transform: translateY(-5px);
    }

    .upcoming-service-box p {
        margin: 8px 0;
        font-size: 0.9em;
        color: #555;
    }

        .upcoming-service-box p strong {
            color: #333;
            font-weight: bold;
        }

.custom-calendar .react-calendar__tile:first-child,
.custom-calendar .react-calendar__tile:last-child {
    background-color: transparent; /* Setzt die Hintergrundfarbe auf transparent */
    color: inherit; /* �bernimmt die Standardtextfarbe */
}

/* Responsive Design f�r kleinere Bildschirme */
@media (max-width: 768px) {
    .upcoming-services-container {
        flex-direction: column;
        align-items: center;
    }

    .upcoming-service-box {
        width: 90%;
    }

    .custom-calendar .react-calendar__tile {
        height: 80px; /* Reduziert die H�he der Kacheln auf mobilen Ger�ten */
    }

    .custom-calendar .react-calendar__navigation {
        padding: 5px;
    }
}
